import { useNavigate, useParams } from "react-router-dom";
import { _gallery } from "../../_mocks/gallery";
import "../../styles/detail/detail.css";
import useResponse from "../../hooks/useResponse";
import { useDispatch } from "react-redux";
import { setInquiryId } from "../../redux/slices/inquiry";

type Props = {};

export default ({}: Props) => {
  const { id } = useParams();
  const { isDesktop } = useResponse();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDetailId = () => {
    if (id) {
      return +id - 1;
    } else {
      return 1;
    }
  };

  return (
    <div className="detail-container">
      <div className="detail-inner">
        <div className="detail-title-container">
          <div className="detail-title-title spoqa-bold">{_gallery[getDetailId()].title}</div>
          {isDesktop && (
            <div
              className="detail-title-list cursor-pointer"
              onClick={() => {
                navigate("/gallery");
              }}
            >
              <div className="detail-title-list-icon" />
              <div className="detail-title-list-text spoqa-bold">목록으로</div>
            </div>
          )}
        </div>
        <div className="detail-divider" />
        <div className="detail-content spoqa-regular">{_gallery[getDetailId()].content}</div>
        <div className={`detail-image detail-image-${id}`} />
        <div
          className={`detail-image-${id} detail-inquiry-button cursor-pointer`}
          onClick={() => {
            dispatch(setInquiryId(id));
            navigate("/inquiry");
          }}
        >
          <div className="detail-inquiry-button-inner">
            <div className="detail-inquiry-button-text spoqa-bold">문의하기</div>
            <div className="detail-inquiry-button-icon" />
          </div>
        </div>

        {/* <div
          className="detail-x-icon cursor-pointer"
          onClick={() => {
            navigate("/gallery");
          }}
        /> */}
      </div>
    </div>
  );
};
