//isEmpty
export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isEmptyArray = (arr: any) => {
  if (Array.isArray(arr) && arr.length === 0) return true;
  return false;
};

//delay
export const delay = (ms: number = 0) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(1);
    }, ms);
  });
};

export const isJson = (str: any) => {
  if (typeof str !== "string") {
    return false;
  }

  try {
    const obj = JSON.parse(str);
    return !!obj && typeof obj === "object";
  } catch (e) {
    return false;
  }
};

//replace
export const replaceComma = (string: string) => {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//Regex
export const integerRegex = (number: any) => {
  if (!/^\d+$/.test(number)) {
    return false;
  } else {
    return true;
  }
};

export const loginIdRegex = (loginId: string) => {
  if (!/^[A-Za-z0-9@$!%*#?&_-]{5,20}$/.test(loginId)) {
    return false;
  }
  return true;
};

export const passwordRegex = (password: string) => {
  if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,16}$/.test(password)) {
    return false;
  }
  return true;
};

export const phoneRegex = (phone: string) => {
  if (!/^[0-9]{1,11}$/.test(phone)) {
    return false;
  }
  return true;
};

export const accountRegex = (account: string) => {
  if (!/^\d{10,14}$/.test(account)) {
    return false;
  }
  return true;
};

export const nicknameRegex = (nickname: string) => {
  if (!/^[A-Za-z0-9가-힣]{2,20}$/.test(nickname)) {
    return false;
  }
  return true;
};

export const ipRegex = (ip: string) => {
  if (
    !/^(([1-9]?\d|1\d{2}|2([0-4]\d)|25[0-5])\.){3}([1-9]?\d|1\d{2}|2([0-4]\d)|25[0-5])$/.test(ip)
  ) {
    return false;
  }
  return true;
};

export function objFromArray(array: any[], key = "id") {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;

    return accumulator;
  }, {});
}
