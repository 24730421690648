import { useNavigate } from "react-router-dom";
import "../../styles/main/main1.css";

export default () => {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <div className="main-inner-container">
        <div className="main-inner">
          <div className="main-inner-box">
            <div className="main-title-1 spoqa-bold">From November 2023</div>
            <div className="main-title-2 spoqa-bold">
              WELCOME TO
              <br />
              THE HYOSANGALLERY
            </div>
            <div
              className="main-button cursor-pointer spoqa-bold"
              onClick={() => {
                navigate("/gallery");
              }}
            >
              더 알아보기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
