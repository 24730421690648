import { combineReducers } from "@reduxjs/toolkit";
import inquirySlice from "./slices/inquiry";

// 위에서 만들었던 slice를 추가한다
const rootReducer = combineReducers({
  inquiry: inquirySlice.reducer,
});

// 타입 에러 막는거
// 이건 그냥 외우거나 복붙하는 수 밖에 없음
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
