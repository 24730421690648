import { useLocation, useNavigate } from "react-router-dom";
import "../styles/header.css";
import useResponse from "../hooks/useResponse";
import { delay } from "../utils/utilFunction";

type Props = { isOpenMenu: boolean; onOpenMenu: () => void; onCloseMenu: () => void };

export default ({ isOpenMenu, onOpenMenu, onCloseMenu }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isDesktop, isTablet, isMobile } = useResponse();

  const path = location.pathname.replaceAll("/", "");

  const isInclude = (_path: "main" | "gallery" | "inquiry") => {
    if (_path == "main" && !path) {
      return true;
    }

    if (_path == "gallery" && path.includes("detail")) {
      return true;
    }

    if (_path == path) {
      return true;
    }
  };

  const onClickNavigate = async (_path: "main" | "gallery" | "inquiry") => {
    if (_path == "main" && !path) {
      await delay(200);
      return window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    if (_path == path) {
      await delay(200);
      return window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    if (_path != path) {
      navigate(`/${_path}`);
    }
  };

  return (
    <div className="header-container">
      <div className="header-inner">
        <div
          className="header-logo cursor-pointer"
          onClick={() => {
            onClickNavigate("main");
          }}
        />
        {isDesktop && (
          <div className="header-navigation">
            <div
              className={`${
                isInclude("main") ? "header-navigation-active" : "header-navigation-inActive"
              } hogook-bold cursor-pointer`}
              onClick={() => {
                onClickNavigate("main");
              }}
            >
              홈
            </div>
            <div
              className={`${
                isInclude("gallery") ? "header-navigation-active" : "header-navigation-inActive"
              } hogook-bold cursor-pointer`}
              onClick={() => {
                onClickNavigate("gallery");
              }}
            >
              갤러리
            </div>
            <div
              className={`${
                isInclude("inquiry") ? "header-navigation-active" : "header-navigation-inActive"
              } hogook-bold cursor-pointer`}
              onClick={() => {
                onClickNavigate("inquiry");
              }}
            >
              구매문의
            </div>
          </div>
        )}
      </div>
      {!isDesktop && (
        <div className="header-button-container">
          {isOpenMenu ? (
            <div
              className="header-x cursor-pointer"
              onClick={() => {
                onCloseMenu();
              }}
            />
          ) : (
            <div
              className="header-menu cursor-pointer"
              onClick={() => {
                onOpenMenu();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
