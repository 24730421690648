import "../styles/footer.css";

export default () => {
  return (
    <div className="footer-container">
      <div className="footer-copyright spoqa-regular">
        Copyright 2023. 효선갤러리. All rights reserved
      </div>
    </div>
  );
};
