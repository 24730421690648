import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inquiryId: 0,
};

const inquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {
    setInquiryId(state, action) {
      state.inquiryId = action.payload;
    },
    clearInquiryId(state) {
      state.inquiryId = 0;
    },
  },
});

export const { setInquiryId, clearInquiryId } = inquirySlice.actions;

export default inquirySlice;
