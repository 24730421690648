import MainOne from "./Main1";
import MainTwo from "./Main2";
import MainThird from "./Main3";
import MainFour from "./Main4";

export default () => {
  return (
    <>
      <MainOne />
      <MainTwo />
      <MainThird />
      <MainFour />
    </>
  );
};
