import { useNavigate } from "react-router-dom";
import { _gallery } from "../../_mocks/gallery";
import useResponse from "../../hooks/useResponse";
import "../../styles/gallery/gallery.css";
import { delay } from "../../utils/utilFunction";
import useInquiry from "../../hooks/useInquiry";
import { useDispatch } from "react-redux";
import { setInquiryId } from "../../redux/slices/inquiry";

type Props = {
  isGallery?: boolean;
};

export default ({ isGallery }: Props) => {
  const { isDesktop, isTablet, isMobile } = useResponse();
  const dispatch = useDispatch();
  const inquiryId = useInquiry();

  const d_First = [1, 2, 3, 4];
  const d_Second = [5, 6, 7, 8];
  const d_Third = [9, 10, 11, 12];
  const d_Four = [13, 14, 15];

  const desktopOrder = [d_First, d_Second, d_Third, d_Four];

  const t_First = [1, 9, 2, 10, 3, 11, 4, 12];
  const t_Second = [5, 13, 6, 14, 7, 15, 8];

  const tabletOrder = [t_First, t_Second];

  const mobileOrder = [1, 5, 9, 13, 2, 6, 10, 14, 3, 7, 11, 15, 4, 8, 12];

  const navigate = useNavigate();

  const isSelected = (id: number) => {
    if (id == inquiryId) {
      return "gallery-inactive";
    } else {
      return "";
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="gallery-image-container">
      {isDesktop && (
        <>
          {desktopOrder.map((_order, i) => {
            return (
              <div className="gallery-image-column-box" key={_order[i]}>
                {_gallery.map((gallery) => {
                  if (_order.includes(gallery.id)) {
                    return (
                      <div
                        className={`gallery-image-box ${isSelected(gallery.id)}`}
                        key={gallery.id}
                      >
                        <div
                          className={`gallery-image gallery-image-${gallery.id} cursor-pointer `}
                          onClick={async () => {
                            if (!isGallery) {
                              dispatch(setInquiryId(gallery.id));
                              await delay(150);
                              goToTop();
                            } else if (isGallery) {
                              navigate(`/detail/${gallery.id}`);
                            }
                          }}
                        />
                        <div
                          className="cursor-pointer"
                          onClick={async () => {
                            if (!isGallery) {
                              dispatch(setInquiryId(gallery.id));
                              await delay(150);
                              goToTop();
                            } else if (isGallery) {
                              navigate(`/detail/${gallery.id}`);
                            }
                          }}
                        >
                          <div className="gallery-image-title spoqa-bold">{gallery.title}</div>
                          <div className="gallery-image-content spoqa-regular">
                            {gallery.content}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </>
      )}

      {isTablet && (
        <div className="gallery-image-container">
          {tabletOrder.map((array) => {
            return (
              <div className="gallery-image-vertical-box">
                {array.map((number) => {
                  return (
                    <div>
                      <div
                        className={`gallery-image ${isSelected(number)} gallery-image-${
                          _gallery[number - 1].id
                        } cursor-pointer`}
                        onClick={async () => {
                          if (!isGallery) {
                            dispatch(setInquiryId(_gallery[number - 1].id));
                            await delay(150);
                            goToTop();
                          } else if (isGallery) {
                            navigate(`/detail/${_gallery[number - 1].id}`);
                          }
                        }}
                      />
                      <div
                        className="cursor-pointer"
                        onClick={async () => {
                          if (!isGallery) {
                            dispatch(setInquiryId(_gallery[number - 1].id));
                            await delay(150);
                            goToTop();
                          } else if (isGallery) {
                            navigate(`/detail/${_gallery[number - 1].id}`);
                          }
                        }}
                      >
                        <div className="gallery-image-title spoqa-bold">
                          {_gallery[number - 1].title}
                        </div>
                        <div className="gallery-image-content spoqa-regular">
                          {_gallery[number - 1].content}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      {isMobile && (
        <div className={`gallery-image-container`}>
          {mobileOrder.map((number) => {
            return (
              <div className={`gallery-image-inner ${isSelected(number)}`}>
                <div
                  className={`gallery-image gallery-image-${number} cursor-pointer`}
                  onClick={async () => {
                    if (!isGallery) {
                      dispatch(setInquiryId(_gallery[number - 1].id));
                      await delay(150);
                      goToTop();
                    } else if (isGallery) {
                      navigate(`/detail/${_gallery[number - 1].id}`);
                    }
                  }}
                />
                <div
                  className="cursor-pointer"
                  onClick={async () => {
                    if (!isGallery) {
                      dispatch(setInquiryId(number));
                      await delay(150);
                      goToTop();
                    } else if (isGallery) {
                      navigate(`/detail/${number}`);
                    }
                  }}
                >
                  <div className="gallery-image-title spoqa-bold">{_gallery[number - 1].title}</div>
                  <div className="gallery-image-content spoqa-regular">
                    {_gallery[number - 1].content}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
