import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/menu/menu.css";

type Props = {
  onCloseMenu: () => void;
};

export default ({ onCloseMenu }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.replaceAll("/", "");

  const isInclude = (_path: "main" | "gallery" | "inquiry") => {
    if (_path == "main" && !path) {
      return true;
    }

    if (_path == "gallery" && path.includes("detail")) {
      return true;
    }

    if (_path == path) {
      return true;
    }
  };

  const onMove = (_path: "main" | "gallery" | "inquiry") => {
    if (_path == "main") {
      navigate("");
    }

    if (_path == "gallery") {
      navigate("/gallery");
    }

    if (_path == "inquiry") {
      navigate("/inquiry");
    }

    onCloseMenu();
  };

  return (
    <div className="menu-container">
      <div className="menu-inner">
        <div className="menu-title spoqa-bold">From November 2023</div>
        <div className="menu-content spoqa-bold">
          WELCOME TO
          <br />
          THE HYOSANGALLERY
        </div>

        <div className="menu-navigation-container">
          <div
            className="menu-navigation-box cursor-pointer"
            onClick={() => {
              onMove("main");
            }}
          >
            <div
              className={`menu-navigation-text hogook-bold ${
                isInclude("main") && "active-navigation"
              }`}
            >
              홈
            </div>
          </div>
          <div
            className="menu-navigation-box cursor-pointer"
            onClick={() => {
              onMove("gallery");
            }}
          >
            <div
              className={`menu-navigation-text hogook-bold ${
                isInclude("gallery") && "active-navigation"
              }`}
            >
              갤러리
            </div>
          </div>
          <div
            className="menu-navigation-box cursor-pointer"
            onClick={() => {
              onMove("inquiry");
            }}
          >
            <div
              className={`menu-navigation-text hogook-bold ${
                isInclude("inquiry") && "active-navigation"
              }`}
            >
              구매문의
            </div>
          </div>
        </div>
      </div>
      <div className="menu-copyright spoqa-regular">
        Copyright 2023. 효선갤러리. All rights reserved
      </div>
    </div>
  );
};
