export const _gallery = [
  {
    id: 1,
    title: "제목 1",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 2,
    title: "제목 2",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 3,
    title: "제목 3",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 4,
    title: "제목 4",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 5,
    title: "제목 5",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 6,
    title: "제목 6",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 7,
    title: "제목 7",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 8,
    title: "제목 8",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 9,
    title: "제목 9",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 10,
    title: "제목 10",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 11,
    title: "제목 11",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 12,
    title: "제목 12",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 13,
    title: "제목 13",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 14,
    title: "제목 14",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
  {
    id: 15,
    title: "제목 15",
    content:
      "(그림 설명) 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서 발전한 미술 양식을 가리킵니다.",
  },
];
