import { useNavigate } from "react-router-dom";
import "../../styles/main/main3.css";
import useResponse from "../../hooks/useResponse";

export default () => {
  const navigate = useNavigate();
  const { isDesktop, isTablet, isMobile } = useResponse();

  return (
    <div className="main3-container">
      {isDesktop && (
        <>
          <div className="main3-background" />
          <div className="main3-title-container">
            <div className="main3-title-inner1">
              <div className="main3-title-inner1-text spoqa-bold">
                동양화와 수묵화의
                <br />
                아름다움이 만나는 공간
              </div>
            </div>
            <div className="main3-title-inner2">
              <div className="main3-title-inner2-text spoqa-regular">
                동양화와 수묵화의 미적 감각을 경험할 수 있는 특별한 장소입니다.
                <br />
                우리 갤러리는 동양 예술의 전통과 현대적인 감각을 조화롭게 결합하며,
                <br />
                아름다움의 새로운 형태를 탐구합니다.
                <br />
                우리의 작품은 감성적이고, 정교하며, 표현력이 풍부하며,
                <br />
                동시에 흥미로운 스토리를 담고 있습니다.
                <br />
                효선갤러리는 예술의 매력과 힘을 여러분과 나누고자 합니다.
                <br />
                예술을 통해 새로운 세계로 안내해 드립니다.
              </div>
            </div>
            <div
              className="main3-title-inner3 cursor-pointer"
              onClick={() => {
                navigate("/gallery");
              }}
            >
              <div className="main3-title-inner3-text spoqa-bold">더 많은 그림 보러가기</div>
              <div className="main3-title-inner3-icon" />
            </div>
          </div>
          <div className="main3-image" />
        </>
      )}

      {isTablet && (
        <>
          <div className="main3-inner">
            <div className="main3-title1 spoqa-bold">
              동양화와 수묵화의
              <br />
              아름다움이 만나는 공간
            </div>
            <div className="main3-title2 spoqa-regular">
              동양화와 수묵화의 미적 감각을 경험할 수 있는 특별한 장소입니다.
              <br />
              우리 갤러리는 동양 예술의 전통과 현대적인 감각을 조화롭게 결합하며,
              <br />
              아름다움의 새로운 형태를 탐구합니다.
              <br />
              우리의 작품은 감성적이고, 정교하며, 표현력이 풍부하며,
              <br />
              동시에 흥미로운 스토리를 담고 있습니다.
              <br />
              효선갤러리는 예술의 매력과 힘을 여러분과 나누고자 합니다.
              <br />
              예술을 통해 새로운 세계로 안내해 드립니다.
            </div>
            <div
              className="main3-title3 cursor-pointer"
              onClick={() => {
                navigate("/gallery");
              }}
            >
              <div className="main3-title3-inner1 spoqa-bold">더 많은 그림 보러가기</div>
              <div className="main3-title3-inner2" />
            </div>
          </div>
          <div className="main3-image" />
        </>
      )}

      {isMobile && (
        <>
          <div className="main3-inner">
            <div className="main3-title-container">
              <div className="main3-title-inner1 spoqa-bold">
                동양화와 수묵화의
                <br />
                아름다움이 만나는 공간
              </div>
              <div className="main3-title-inner2 spoqa-regular">
                동양화와 수묵화의 미적 감각을 경험할 수 있는 특별한 장소입니다.
                <br />
                우리의 작품은 감성적이고, 정교하며, 표현력이 풍부하며, 동시에 흥미로운 스토리를 담고
                있습니다.
                <br />
                효선갤러리는 예술의 매력과 힘을 여러분과 나누고자 합니다.
              </div>
              <div
                className="main3-title-inner3 cursor-pointer"
                onClick={() => {
                  navigate("/gallery");
                }}
              >
                <div className="main3-title-inner3-text spoqa-bold">더 많은 그림 보러가기</div>
                <div className="main3-title-inner3-icon" />
              </div>
            </div>
          </div>
          <div className="main3-image" />
        </>
      )}
    </div>
  );
};
