import Swal, { SweetAlertIcon } from "sweetalert2";

type Props = {
  title: string;
  html: any;
  icon: SweetAlertIcon;
};

export default (title: string, html: any, icon: SweetAlertIcon) => {
  return Swal.fire({ title, html, icon });
};
