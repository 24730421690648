import { useNavigate } from "react-router-dom";
import "../../styles/main/main4.css";
import useResponse from "../../hooks/useResponse";
import { _gallery } from "../../_mocks/gallery";

export default () => {
  const navigate = useNavigate();

  const { isDesktop, isTablet, isMobile } = useResponse();

  const mainArray = [_gallery[3], _gallery[4], _gallery[5], _gallery[6]];

  const mainArray1 = [_gallery[3], _gallery[4]];
  const mainArray2 = [_gallery[5], _gallery[6]];
  const mainTotal = [mainArray1, mainArray2];

  const mobileMainArray = [_gallery[3], _gallery[5], _gallery[4], _gallery[6]];

  return (
    <div className="main4-container">
      {isDesktop && (
        <div className="main4-inner">
          <div className="main4-title-container">
            <div className="main4-title-container">
              <div className="main4-title-title spoqa-bold">Gallery</div>
              <div
                className="main4-title-inquiry cursor-pointer"
                onClick={() => {
                  navigate("/gallery");
                }}
              >
                <div className="main4-title-inquiry-title spoqa-bold">더 많은 그림 보러가기</div>
                <div className="main4-title-inquiry-icon" />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="main4-horizontal-divider" />
          {/*  */}
          <div className="main4-title-text spoqa-regular">
            - 동양 미술에서 사용되는 기법 중 하나로, 채색을 사용하지 않고 오로지 먹의 농담을 통해
            그려지는 예술입니다.
            <br />- 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서
            발전한 미술 양식을 가리킵니다.
          </div>

          <div className="main4-image-container">
            {/*  */}
            {mainArray.map((info) => {
              return (
                <div className="main4-image-box">
                  <div className={`main4-image-box${info.id}-image`} />
                  <div className="main4-image-box-content-contaienr">
                    <div className="main4-image-box-content-title spoqa-bold">{info.title}</div>
                    <div className="main4-image-box-content-content spoqa-regular">
                      {info.content}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isTablet && (
        <div className="main4-inner">
          <div className="main4-title-container">
            <div className="main4-title-box">
              <div className="main4-title-inner1 spoqa-bold">Gallery</div>
              <div
                className="main4-title-inner2 cursor-pointer"
                onClick={() => {
                  navigate("/gallery");
                }}
              >
                <div className="main4-title-inner-text spoqa-bold">더 많은 그림 보러가기</div>
                <div className="main4-title-inner-icon" />
              </div>
            </div>
            <div className="main4-horizontal-divider" />
            <div className="main4-content spoqa-regular">
              - 동양 미술에서 사용되는 기법 중 하나로, 채색을 사용하지 않고 오로지 먹의 농담을 통해
              그려지는 예술입니다.
              <br />- 동아시아 지역의 미술을 일컫는 용어로, 주로 중국, 일본, 한국 등 동양 국가들에서
              발전한 미술 양식을 가리킵니다.
            </div>
          </div>
          <div className="main4-image-container">
            {mainTotal.map((array) => {
              return (
                <div className="main4-image-vertical-box">
                  {array.map((info) => {
                    return (
                      <div>
                        <div className={`main4-image main4-image-${info.id}`} />
                        <div className="main4-image-title spoqa-bold">{info.title}</div>
                        <div className="main4-image-content spoqa-regular">{info.content}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isMobile && (
        <div className="main4-inner">
          <div className="main4-title-container">
            <div className="main4-title-text spoqa-bold">Gallery</div>
            <div
              className="main4-title-gallery cursor-pointer"
              onClick={() => {
                navigate("/gallery");
              }}
            >
              <div className="main4-title-gallery-text spoqa-bold">더 많은 그림 보러가기</div>
              <div className="main4-title-gallery-icon" />
            </div>
          </div>
          <div className="main4-horizontal-divider" />
          <div className="main4-title-content spoqa-regular">
            - 동양 미술에서 사용되는 기법 중 하나로, 채색을 사용하지 않고 오로지 먹의 농담을 통해
            그려지는 예술입니다.
            <br />- 동아시아 지역의 미술을 일컫는 용어로, 중국, 일본, 한국 등 동양 국가들에서 발전한
            미술 양식을 가리킵니다.
          </div>
          <div className={`main4-image-container`}>
            {mobileMainArray.map((info) => {
              return (
                <div className="main4-image-inner">
                  <div className={`main4-image main4-image-${info.id}`} />
                  <div className="main4-title-title spoqa-bold">{info.title}</div>
                  <div className="main4-title-content spoqa-regular">{info.content}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
