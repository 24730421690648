import { useNavigate } from "react-router-dom";
import "../../styles/main/main2.css";
import useResponse from "../../hooks/useResponse";
import { _gallery } from "../../_mocks/gallery";
import { useDispatch } from "react-redux";
import { setInquiryId } from "../../redux/slices/inquiry";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop, isTablet, isMobile } = useResponse();

  const mainArray = [
    { ..._gallery[0], number: 2 },
    { ..._gallery[1], number: 4 },
    { ..._gallery[2], number: 9 },
  ];

  return (
    <div className="main2-container">
      {isDesktop && (
        <>
          {mainArray.map((info) => {
            return (
              <>
                <div className="main2-image-container">
                  <div className="main2-image-inner">
                    <div className="main2-title-container">
                      <div className="main2-title-inner1 spoqa-bold">{info.title}</div>
                      <div
                        className="main2-title-inner2-container cursor-pointer"
                        onClick={() => {
                          dispatch(setInquiryId(info.number));
                          navigate("/inquiry");
                        }}
                      >
                        <div className="main2-title-inner2-container-inner1 spoqa-bold">
                          구매문의
                        </div>
                        <div className="main2-title-inner2-container-inner2" />
                      </div>
                    </div>
                    <div className="main2-horizontal-divider-black" />
                    <div className={`main2-image-box main2-image${info.id}`} />
                    <div className="main2-image-content-container">
                      <span className="main2-image-content-inner spoqa-regular">
                        {info.content}
                      </span>
                    </div>
                    <div className="main2-horizontal-divider-gray" />
                  </div>
                </div>
                {info.id != 3 && <div className="main2-vertical-divider" />}
              </>
            );
          })}
        </>
      )}
      {isTablet && (
        <div className="main2-image-container">
          {mainArray.map((info) => {
            return (
              <>
                <div className="main2-image-box">
                  <div className="main2-image-title-container">
                    <div className="main2-image-title-container-inner1 spoqa-bold">
                      {info.title}
                    </div>
                    <div
                      className="main2-image-title-container-inner2 cursor-pointer"
                      onClick={() => {
                        dispatch(setInquiryId(info.number));
                        navigate("/inquiry");
                      }}
                    >
                      <div className="main2-image-title-container-inner-inner1 spoqa-bold">
                        구매문의
                      </div>
                      <div className="main2-image-title-container-inner-inner2" />
                    </div>
                  </div>
                  <div className="main2-image-info-container">
                    <div className="main2-horizontal-divider-black" />
                    <div className={`main2-image main2-image${info.id}`} />
                    <div className="main2-image-content-container spoqa-regular">
                      {info.content}
                    </div>
                  </div>
                </div>
                {info.id != 3 && <div className="main2-horizontal-divider-gray" />}
              </>
            );
          })}
        </div>
      )}

      {isMobile && (
        <div className="main2-image-container">
          {mainArray.map((info) => {
            return (
              <>
                <>
                  <div className="main2-inner">
                    <div
                      className="main2-image-title cursor-pointer"
                      onClick={() => {
                        dispatch(setInquiryId(info.number));
                        navigate("/inquiry");
                      }}
                    >
                      <div className="main2-image-title-text spoqa-bold">{info.title}</div>
                      <div className="main2-image-title-icon" />
                    </div>
                    <div className="main2-horizontal-divider-black" />
                    <div className={`main2-image main2-image${info.id}`} />
                    <div className="main2-content spoqa-regular">{info.content}</div>
                  </div>
                </>
                {info.id != 3 && <div className="main2-horizontal-divider-gray" />}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};
