import { useEffect, useRef, useState } from "react";
import { _gallery } from "../../_mocks/gallery";
import GalleryList from "../../components/gallery/GalleryList";
import "../../styles/inquiry/inquiry.css";
import useResponse from "../../hooks/useResponse";
import Sweetalert from "../../components/Sweetalert";
import useInquiry from "../../hooks/useInquiry";
import { useDispatch } from "react-redux";
import { clearInquiryId } from "../../redux/slices/inquiry";
import { integerRegex } from "../../utils/utilFunction";

export default () => {
  const dispatch = useDispatch();
  const inquiryId = useInquiry();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [inquiry, setInquiry] = useState("");

  const nameRef = useRef<any>();
  const phoneRef = useRef<any>();
  const emailRef = useRef<any>();
  const inquiryRef = useRef<any>();

  const galleryRef = useRef<any>();

  const { isMobile, isBigDesktop, isDesktop, isTablet } = useResponse();

  const isNull = inquiryId == 0;

  useEffect(() => {
    return () => {
      dispatch(clearInquiryId());
    };
  }, []);

  useEffect(() => {
    if (inquiryId != 0) {
      onClear();
    }
  }, [inquiryId]);

  const onClear = () => {
    setName("");
    setPhone("");
    setEmail("");
    setInquiry("");
  };

  const getPx = () => {
    if (isMobile) {
      return { top: 80, bottom: 40 };
    } else if (isTablet) {
      return { top: 100, bottom: 80 };
    } else {
      return { top: 160, bottom: 120 };
    }
  };

  const goToGallery = () => galleryRef.current?.scrollIntoView({ behavior: "smooth" });

  const onChangeName = (newName: string) => {
    setName(newName);
  };

  const onChangePhone = (newPhone: string) => {
    if (integerRegex(newPhone) || !newPhone) {
      setPhone(newPhone);
    } else {
      return;
    }
  };

  const onChangeEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  const onChangeInquiry = (newInquiry: string) => {
    setInquiry(newInquiry);
  };

  const onSubmit = () => {
    if (!name || !phone || !email || !inquiry) {
      return Sweetalert("구매문의 실패", "모든 항목을 기입해주세요.", "error");
    } else {
      console.log("");
    }
  };

  return (
    <div className="inquiry-container">
      <div className="inquiry-container-inner">
        {/*  */}
        <div className="inquiry-title-container spoqa-bold">Inquiry</div>
        <div className="inquiry-horizontal-divider" />
        <div className="inquiry-title-content spoqa-regular">
          - 이메일 또는 연락처를 정확하게 입력해주셔야 정확한 문의를 받아보실 수 있습니다.
          <br />- 문의사항을 구체적으로 작성해주시면 더 빠르게 답변을 받아보실 수 있습니다.
        </div>

        {isDesktop && (
          <div className="inquiry-inquiry-container">
            <div className={`inquiry-inquiry-image ${isNull && "null-image"}`}>
              <div className={`inquiry-image-inner inquiry-image-${inquiryId}`} />
              {isNull && (
                <div className="inquiry-null-text spoqa-bold">문의하실 작품을 선택해 주세요</div>
              )}
            </div>

            <div className="inquiry-inquiry-input-container">
              {isBigDesktop && !isNull && (
                <div
                  className="inquiry-inquiry-input-title-research cursor-pointer"
                  onClick={() => {
                    goToGallery();
                  }}
                >
                  <div className="inquiry-research-arrow" />
                  <div className="inquiry-research-text spoqa-bold">다시 선택</div>
                </div>
              )}

              <div className="inquiry-inquiry-input-title">
                {/*  */}
                {!isNull && !isBigDesktop && (
                  <div className="inquiry-inquiry-input-title-research-container cursor-pointer">
                    <div className="inquiry-inquiry-input-title-1 spoqa-bold">
                      {isNull ? "제목" : _gallery[inquiryId - 1].title}
                    </div>
                    <div
                      className="inquiry-inquiry-input-title-research"
                      onClick={() => {
                        goToGallery();
                      }}
                    >
                      <div className="inquiry-research-arrow" />
                      <div className="inquiry-research-text spoqa-bold">다시 선택</div>
                    </div>
                  </div>
                )}
                {(isNull || (!isNull && isBigDesktop)) && (
                  <div className="inquiry-inquiry-input-title-1 spoqa-bold">
                    {isNull ? "제목" : _gallery[inquiryId - 1].title}
                  </div>
                )}
                <div className="inquiry-inquiry-input-title-2 spoqa-regular">
                  {isNull ? "작품을 선택해주세요." : _gallery[inquiryId - 1].content}
                </div>
              </div>
              <div className="inquiry-inquiry-input-title-3 spoqa-medium">회신 받으실 정보</div>
              <div className="inquiry-input-container">
                <div className="inquiry-input-row-container">
                  <div className="inquiry-input-small-container">
                    <div
                      className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                      onClick={() => {
                        if (!isNull) {
                          nameRef.current.focus();
                        }
                      }}
                    >
                      <div
                        className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                      >
                        이름
                      </div>
                      <input
                        className="inquiry-input spoqa-bold"
                        ref={nameRef}
                        value={name}
                        onChange={(e) => {
                          onChangeName(e.target.value);
                        }}
                        disabled={isNull}
                      />
                    </div>
                    <div
                      className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                      onClick={() => {
                        if (!isNull) {
                          phoneRef.current.focus();
                        }
                      }}
                    >
                      <div
                        className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                      >
                        연락처
                      </div>
                      <input
                        className="inquiry-input spoqa-bold"
                        ref={phoneRef}
                        value={phone}
                        onChange={(e) => {
                          onChangePhone(e.target.value);
                        }}
                        disabled={isNull}
                        maxLength={11}
                      />
                    </div>
                    <div
                      className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                      onClick={() => {
                        if (!isNull) {
                          emailRef.current.focus();
                        }
                      }}
                    >
                      <div
                        className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                      >
                        이메일
                      </div>
                      <input
                        className="inquiry-input spoqa-bold"
                        ref={emailRef}
                        value={email}
                        onChange={(e) => {
                          onChangeEmail(e.target.value);
                        }}
                        disabled={isNull}
                      />
                    </div>
                  </div>
                  <div
                    className={`inquiry-input-big-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        inquiryRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      문의사항
                    </div>
                    <textarea
                      className="inquiry-textarea spoqa-bold"
                      ref={inquiryRef}
                      value={inquiry}
                      onChange={(e) => {
                        onChangeInquiry(e.target.value);
                      }}
                      disabled={isNull}
                    />
                  </div>
                </div>
                <div
                  className={`inquiry-button ${!isNull && "cursor-pointer"}`}
                  onClick={() => {
                    if (!isNull) {
                      onSubmit();
                    }
                  }}
                >
                  <div className="inquiry-button-inner">
                    <div
                      className={`inquiry-button-text spoqa-bold ${
                        !isNull && "active-button-text"
                      }`}
                    >
                      문의하기
                    </div>
                    <div className={`inquiry-button-icon ${!isNull && "active-button-icon"}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isTablet && (
          <>
            <div
              className={`inquiry-inquiry-image ${isNull && "null-image"} ${
                !isNull && `inquiry-image-${inquiryId}`
              }`}
            >
              {isNull && (
                <div className="inquiry-null-text spoqa-bold">문의하실 작품을 선택해 주세요</div>
              )}
            </div>
            <div className="inquiry-input-title-research-container">
              <div className="inquiry-input-title-1 spoqa-bold">
                {isNull ? "제목" : `${_gallery[inquiryId - 1].title}`}
              </div>
              {!isNull && (
                <div
                  className="inquiry-research-container cursor-pointer"
                  onClick={() => {
                    goToGallery();
                  }}
                >
                  <div className="inquiry-research-icon" />
                  <div className="inquiry-research-text spoqa-bold">다시 선택</div>
                </div>
              )}
            </div>
            <div className="inquiry-input-title-2 spoqa-regular">
              {isNull ? "작품을 선택해주세요." : `${_gallery[inquiryId - 1].content}`}
            </div>

            <div className="inquiry-input-title spoqa-medium">회신 받으실 정보</div>

            <div className="inquiry-input-container">
              <div className="inquiry-input-vertical-container">
                <div className="inquiry-input-horizontal-container">
                  <div
                    className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        nameRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      이름
                    </div>
                    <input
                      className="inquiry-input spoqa-bold"
                      ref={nameRef}
                      value={name}
                      onChange={(e) => {
                        onChangeName(e.target.value);
                      }}
                      disabled={isNull}
                    />
                  </div>
                  <div
                    className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        phoneRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      연락처
                    </div>
                    <input
                      className="inquiry-input spoqa-bold"
                      ref={phoneRef}
                      value={phone}
                      onChange={(e) => {
                        onChangePhone(e.target.value);
                      }}
                      disabled={isNull}
                      maxLength={11}
                    />
                  </div>
                  <div
                    className={`inquiry-input-email-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        emailRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      이메일
                    </div>
                    <input
                      className="inquiry-input spoqa-bold"
                      ref={emailRef}
                      value={email}
                      onChange={(e) => {
                        onChangeEmail(e.target.value);
                      }}
                      disabled={isNull}
                    />
                  </div>
                </div>
                <div
                  className={`inquiry-input-big-box ${!isNull && "active-input"}`}
                  onClick={() => {
                    if (!isNull) {
                      inquiryRef.current.focus();
                    }
                  }}
                >
                  <div className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}>
                    문의사항
                  </div>
                  <textarea
                    className="inquiry-textarea spoqa-bold"
                    ref={inquiryRef}
                    value={inquiry}
                    onChange={(e) => {
                      onChangeInquiry(e.target.value);
                    }}
                    disabled={isNull}
                  />
                </div>
                <div
                  className={`inquiry-button ${!isNull && "cursor-pointer"}`}
                  onClick={() => {
                    if (!isNull) {
                      onSubmit();
                    }
                  }}
                >
                  <div className="inquiry-button-inner">
                    <div
                      className={`inquiry-button-text spoqa-bold ${
                        !isNull && "active-button-text"
                      }`}
                    >
                      문의하기
                    </div>
                    <div className={`inquiry-button-icon ${!isNull && "active-button-icon"}`} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isMobile && (
          <div>
            <div
              className={`inquiry-image ${isNull && "null-image"} ${
                !isNull && `inquiry-image-${inquiryId}`
              }`}
            >
              {isNull && (
                <div className="inquiry-null-text spoqa-regular">문의하실 작품을 선택해 주세요</div>
              )}
            </div>
            {!isNull && (
              <div
                className="inquiry-research-container cursor-pointer"
                onClick={() => {
                  goToGallery();
                }}
              >
                <div className="inquiry-research-icon" />
                <div className="inquiry-research-text spoqa-bold">다시 선택</div>
              </div>
            )}
            <div className="inquiry-image-title spoqa-bold">
              {isNull ? "제목" : `${_gallery[inquiryId - 1].title}`}
            </div>
            <div className="inquiry-image-content spoqa-regular">
              {isNull ? "작품을 선택해주세요." : `${_gallery[inquiryId - 1].content}`}
            </div>
            <div className="inquiry-input-title spoqa-medium">회신 받으실 정보</div>
            <div className="inquiry-input-container">
              <div className="inquiry-input-box-container">
                <div className="inquiry-input-small-container">
                  <div
                    className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        nameRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      이름
                    </div>
                    <input
                      className="inquiry-input spoqa-bold"
                      ref={nameRef}
                      value={name}
                      onChange={(e) => {
                        onChangeName(e.target.value);
                      }}
                      disabled={isNull}
                    />
                  </div>
                  <div
                    className={`inquiry-input-small-box ${!isNull && "active-input"}`}
                    onClick={() => {
                      if (!isNull) {
                        phoneRef.current.focus();
                      }
                    }}
                  >
                    <div
                      className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}
                    >
                      연락처
                    </div>
                    <input
                      className="inquiry-input spoqa-bold"
                      ref={phoneRef}
                      value={phone}
                      onChange={(e) => {
                        onChangePhone(e.target.value);
                      }}
                      disabled={isNull}
                      maxLength={11}
                    />
                  </div>
                </div>
                <div
                  className={`inquiry-input-email-box ${!isNull && "active-input"}`}
                  onClick={() => {
                    if (!isNull) {
                      emailRef.current.focus();
                    }
                  }}
                >
                  <div className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}>
                    이메일
                  </div>
                  <input
                    className="inquiry-input spoqa-bold"
                    ref={emailRef}
                    value={email}
                    onChange={(e) => {
                      onChangeEmail(e.target.value);
                    }}
                    disabled={isNull}
                  />
                </div>
                <div
                  className={`inquiry-input-big-box ${!isNull && "active-input"}`}
                  onClick={() => {
                    if (!isNull) {
                      inquiryRef.current.focus();
                    }
                  }}
                >
                  <div className={`inquiry-label spoqa-regular ${!isNull && "active-input-label"}`}>
                    문의사항
                  </div>
                  <textarea
                    className="inquiry-textarea spoqa-bold"
                    ref={inquiryRef}
                    value={inquiry}
                    onChange={(e) => {
                      onChangeInquiry(e.target.value);
                    }}
                    disabled={isNull}
                  />
                </div>
              </div>
              <div
                className={`inquiry-button ${!isNull && "cursor-pointer"}`}
                onClick={() => {
                  if (!isNull) {
                    onSubmit();
                  }
                }}
              >
                <div className="inquiry-button-inner">
                  <div
                    className={`inquiry-button-text spoqa-bold ${!isNull && "active-button-text"}`}
                  >
                    문의하기
                  </div>
                  <div className={`inquiry-button-icon ${!isNull && "active-button-icon"}`} />
                </div>
              </div>
            </div>
          </div>
        )}

        {/*  */}
        <div
          style={{
            paddingTop: `${getPx().top}px`,
            paddingBottom: `${getPx().bottom}px`,
          }}
          ref={galleryRef}
        >
          <GalleryList />
        </div>
      </div>
    </div>
  );
};
