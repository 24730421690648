import { useEffect } from "react";
import { _gallery } from "../../_mocks/gallery";
import GalleryList from "../../components/gallery/GalleryList";
import useResponse from "../../hooks/useResponse";
import "../../styles/gallery/gallery.css";

export default () => {
  const { isDesktop, isTablet, isMobile } = useResponse();

  const getPx = () => {
    if (isMobile) {
      return { top: 0, bottom: 0 };
    } else if (isTablet) {
      return { top: 30, bottom: 80 };
    } else {
      return { top: 60, bottom: 120 };
    }
  };

  return (
    <div className="gallery-container">
      <div className="gallery-container-inner">
        {/*  */}
        <div className="gallery-title-container spoqa-bold">Gallery</div>
        <div className="gallery-horizontal-divider" />
        <div className="gallery-title-content spoqa-regular">
          - 동양 미술에서 사용되는 기법 중 하나로, 채색을 사용하지 않고 오로지 먹의 농담을 통해
          그려지는 예술입니다.
          <br />- 동아시아 지역의 미술을 일컫는 용어로, {!isMobile && "주로"} 중국, 일본, 한국 등
          동양 국가들에서 발전한 미술 양식을 가리킵니다.
        </div>
        {/*  */}
        <div
          style={{
            paddingTop: `${getPx().top}px`,
            paddingBottom: `${getPx().bottom}px`,
          }}
        >
          <GalleryList isGallery />
        </div>
      </div>
    </div>
  );
};
