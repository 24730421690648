import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useEffect, useState } from "react";
import Main from "../pages/main/Main";
import useResponse from "../hooks/useResponse";
import Header from "../components/Header";
import Gallery from "../pages/gallery/Gallery";

import Footer from "../components/Footer";
import Inquiry from "../pages/inquiry/Inquiry";
import Detail from "../components/detail/Detail";
import Menu from "../components/menu/Menu";

export default () => {
  const { isMobile, isDesktop } = useResponse();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpenMenu]);

  const onOpenMenu = () => {
    setIsOpenMenu(true);
  };

  const onCloseMenu = () => {
    setIsOpenMenu(false);
  };

  return (
    <>
      <Header isOpenMenu={isOpenMenu} onOpenMenu={onOpenMenu} onCloseMenu={onCloseMenu} />

      {!isDesktop && isOpenMenu && <Menu onCloseMenu={onCloseMenu} />}

      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/gallery"
          element={<Gallery />}
          // element={<Gallery setDetailId={setDetailId} onOpenDetail={onOpenDetail} />}
        />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/detail/:id" element={<Detail />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <Footer />
    </>
  );
};
